<template>
  <q-dialog v-model="isDialogOpen" id="address-dialog" persistent @hide="cancel">
    <div>
      <q-form class="bg-white rounded-lg" @submit="clickSubmit">
        <div class="p-2 d-flex flex-wrap gap-2">
          <!-- company -->
          <q-input type="text" v-if="fieldOptions.corpName" outlined dense hide-bottom-space v-model="addressData.corpName" autofocus 
            :label="$t('form.corp_name')|capitalize" class="flex-even m-0 flex-size-6" :rules="[  val=> !val || val.length<=100 || $t('rule.too_long')]">
            <template #prepend><fa-icon :icon="['fad','building']" fixed-width class="text-primary mr-25"/></template>
          </q-input>
          <!-- name -->
          <q-input type="text" outlined dense hide-bottom-space v-model="addressData.name" autofocus 
            :label="$t('form.name')|capitalize" class="flex-even m-0 flex-size-6" :rules="[  val=> Boolean(val), val=>val.length<=100 || $t('rule.too_long')]">
            <template #prepend><fa-icon :icon="['fad','user']" fixed-width class="text-danger mr-25"/></template>
          </q-input>
          <!-- 电话 -->
          <q-input type="tel" outlined dense hide-bottom-space v-model="addressData.phone" :mask="addressData.mask" unmasked-value
            :label="$t('user.phone')|capitalize" class="flex-even phone m-0 flex-size-6" :rules="[  val=> Boolean(val), val=>val.length<=50 || $t('rule.too_long')]">
            <template #prepend>
              <fa-icon :icon="['fad','mobile-android-alt']" fixed-width class="text-danger mr-50"/>
              <q-select ref="codeRef" outlined square dense prefix="+" :options="$global.countries" :label="$t('user.country')" v-model="addressData.country" class="bg-light-primary m-0" popup-content-class="mt-1">
                <template #option="scope">
                  <q-item v-bind="scope.itemProps"  class="d-flex gap-1 align-items-center"
                    @click="addressData.country = scope.opt.value;addressData.mask = scope.opt.mask;$refs.codeRef.hidePopup()">
                    <q-chip dense color="primary" text-color="white">{{scope.opt.value}}</q-chip>{{$t('country.'+scope.opt.label)}}
                  </q-item>
                </template>
              </q-select>
            </template>
          </q-input>

          <!-- unit -->
          <q-input type="text" v-if="fieldOptions.unit" outlined dense hide-bottom-space v-model="addressData.unit" prefix="#"
            :label="$t('form.unit')|capitalize" class="flex-even m-0 flex-size-4" :rules="[ val=> Boolean(val), val=>val.length<=50 || $t('rule.too_long')]">
            <template #prepend><fa-icon :icon="['fad','door-open']" fixed-width class="text-danger mr-25"/></template>
          </q-input>
          <!-- 邮编 -->
          <q-input type="text" v-if="fieldOptions.zip" outlined dense hide-bottom-space v-model="addressData.zip" 
            :label="$t('form.zip')|capitalize" class="flex-even m-0 flex-size-4" :rules="[ val=> Boolean(val), val=> val.length<=10 || $t('rule.too_long')]">
            <template #prepend><fa-icon :icon="['fad','mailbox']" fixed-width class="text-danger mr-25"/></template>
          </q-input>

          <!-- 地址 -->
          <q-input type="textarea" outlined dense hide-bottom-space v-model="addressData.address" rows="3"
            :label="$t('action.address')|capitalize" class="flex-even m-0 flex-size-6" :rules="[ val=> Boolean(val), val=>val.length<=200 || $t('rule.too_long')]">
            <template #prepend><fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-danger mr-25"/></template>
          </q-input>

          <!-- City -->
            <q-input type="text"  v-if="fieldOptions.city" outlined dense hide-bottom-space v-model="addressData.city" 
              :label="$t('form.city')|capitalize" class="flex-even m-0 flex-size-6" :rules="[ val=> Boolean(val), val=>val.length<=100 || $t('rule.too_long')]">
              <template #prepend><fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-danger mr-25"/></template>
            </q-input>
        </div>
        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary">
          <q-btn type="submit" push no-caps color="primary" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="warning" v-close-popup :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize } from '@core/utils/filter'
import $global from '@/globalVariables'

export default {
  name: 'address-dialog',
  filters:{ capitalize },
  setup(){
    const emptyAddress = ()=> ({
      mask: $global.default_phone_mask,
      country: $global.default_country_code,
      city: $global.default_city,
      isDefault: false
    })
    const addressData = ref(emptyAddress())
    const isDialogOpen = ref(false)
    const resetAddress = () => addressData.value = emptyAddress()
    
    const fieldOptions = ref({})
    const resetFieldOptions = () => fieldOptions.value = {corpName: false, unit:true, zip:true, city: true}

    // Actions
    let submit = ()=>{}
    let cancel = ()=>{}
    const clickSubmit = () => submit()

    const open = (obj, fields) =>{
      resetAddress()
      resetFieldOptions()
      if(fields) fieldOptions.value = Object.assign(fieldOptions.value, fields)
      isDialogOpen.value=true
      return new Promise((res)=>{
        if (obj) Object.assign(addressData.value, obj)

        submit = ()=>{
          isDialogOpen.value = false
          res(addressData.value)
        }
        cancel = ()=>{
          isDialogOpen.value = false
          res(false)
          resetAddress()
        }
      })
    }

    return {
      capitalize,
      isDialogOpen,
      fieldOptions,
      addressData,
      open,
      cancel,
      clickSubmit,
    }
  }
}
</script>

<style lang="scss">
#address-dialog {
  .q-textarea {
    .q-field__control{
      padding-right: 2px
    }
  }
  .phone {
    .q-field__prepend{
      .q-field__control-container {
        flex-wrap: nowrap;
        width: 45px
      }
    }
  }
}
</style>
