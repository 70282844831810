<template>
  <div id="list-table" class="card m-0 h-100 shadow-sm rounded-lg">
    <!-- Status Tabs -->
    <q-tabs v-if="tabOptions && tabOptions.length>0" v-model="filterTap" inline-label narrow-indicator align="left" class="h5 border-bottom p-50 mb-0">
      <q-tab v-for="(item, i) in tabOptions" :key="i" :name="typeof item.value === 'string'?item.value:JSON.stringify(item.value)" :class="item.color">
        <q-badge v-if="tabCount(item.key)" :color="item.color" floating rounded>{{tabCount(item.key)}}</q-badge>
        <fa-icon v-if="i18n(`${tabGroup}.${item.key}.icon`)" :icon="['far',$t(`${tabGroup}.${item.key}.icon`)]" :class="`text-${$t(`${tabGroup}.${item.key}.color`)}`"/>
        <span class="ml-50 text-capitalize text-nowrap d-none d-sm-inline" :class="tabTitleColor?`text-${$t(`${tabGroup}.${item.key}.color`)}`:''">{{$t(`${tabGroup}.${item.key}._`)}}</span>
      </q-tab>
    </q-tabs>
    <div class="px-2 py-1 d-flex flex-column flex-sm-row gap-2">
      <div class="d-flex align-items-center justify-content-between justify-content-sm-start gap-2 flex-nowrap" :class="isDialog?'':'flex-even'">
        <div v-if="showIcon" class="d-flex gap-2 align-items-center">
          <fa-icon :icon="icon" size="2x" class="text-primary" />
          <div class="h4 mb-0 mr-1 text-primary text-capitalize">{{$t('member.supplier._')}}</div>
        </div>
        <q-select outlined dense popup-content-class="mt-1" v-model="perPage" :options="perPageOptions" :clearable="false" class="mb-0 d-none d-sm-inline-block">
          <template #prepend><fa-icon :icon="['fas','eye']" fixed-width style="font-size:18px"/></template>
        </q-select>
      </div>
      <!-- Search -->
      <div class="d-flex gap-2 flex-nowrap flex-even">
        <q-input dense filled clearable v-model="searchQuery" class="d-inline-block flex-even mb-0" :placeholder="capitalize($t('action.search'))+'...'" />
        <slot name="add" />
        <!-- 取消选择 -->
        <q-btn v-if="cancelSelect" push color="danger" @click="cancelSelect('clear')">
          <fa-icon icon="users-slash" />
        </q-btn>
      </div>
    </div>

    <BTable ref="tableRef" class="position-relative text-center flex-even" :class="{slim}" :items="fetchTable" striped hover responsive :fields="columns" primary-key="_id" :sort-by.sync="sortBy" :busy="loading"
      show-empty :sort-desc.sync="isSortDesc" @row-clicked="rowClicked" :selectable="selectable" :select-mode="selectMulti?'multi':'single'" @row-selected="onRowSelected"  >
      
      <template #empty>
        <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
        <h5>{{$t("table.noResult")}}</h5>
      </template>

      <!-- Header search -->
      <template #top-row="{ fields }">
        <td v-for="field in fields" :key="field.key">
          <div v-if="field.key=='selected'" class="text-center"><q-checkbox v-model="selectAllCheckbox" @input="selectAll"/></div>
          <div v-else-if="field.noFilter"><q-input disable dense filled :placeholder="field.searchLabel||field.label" class="mb-0"/></div>
          <!--date-->
          <q-input v-else-if="field.isDate" filled dense hide-bottom-space clearable v-model="filterInputs[field.key]" mask="date" class="mb-0">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                  <q-date v-model="filterInputs[field.key]">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
          <!--date range-->
          <div v-else-if="field.isDateRange" class="mb-0">
            <div class="d-flex gap-1 justify-content-center align-items-center">
              <div class="text-left">
                {{filterInputs[field.key]?filterInputs[field.key].from||filterInputs[field.key]:'' | mmdd}}<br>
                {{filterInputs[field.key]?filterInputs[field.key].to||filterInputs[field.key]:'' | mmdd}}
              </div>
              <q-icon name="event" class="cursor-pointer" size="sm"/>
              <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date range v-model="filterInputs[field.key]">
                  <div class="row items-center justify-between">
                    <q-btn @click.stop="()=>filterInputs[field.key]=undefined" :label="$t('action.clear')|capitalize" color="danger" flat />
                    <q-btn v-close-popup :label="$t('action.close')|capitalize" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </div>
          </div>
          <q-input v-else-if="field.key" :type="field.isNumber?'number':'text'" class="mb-0" filled dense clearable v-model="filterInputs[field.key]" :placeholder="field.searchLabel||field.label" 
            :bg-color="filterInputs[field.key]?'light-primary':''" />
        </td>
      </template>

      <!-- Select -->
      <template #cell(selected)="{ rowSelected, index, clickRow, unclickRow }">
        <q-checkbox v-if="selectable" v-model="rowSelected" @input="tableRef.isRowSelected(index)===true?unclickRow(index):clickRow(index)"/>
      </template>

      <!-- Tags -->
      <template #cell(tags)="{item}">
        <div class="d-flex flex-wrap gap-1">
          <q-chip dense v-for="(tag, index) in item.tags" color="light-primary" :key="index" class="m-0">{{tag}}</q-chip>
        </div>
      </template>

      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </BTable>
  
    <div class="p-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">{{ dataMeta.from }} {{$t('table.to')}} {{ dataMeta.to }} {{$t('table.of')}} {{ dataMeta.of }} {{$t('table.items')}}</span>
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
            first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18" /></template>
            <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </div>
</template>

<script>
import { BRow, BCol, BTable, BPagination } from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
import { fileUrl, capitalize, mmdd, i18n } from '@core/utils/filter'
import { Dialog } from '@core/utils/other'
import { useDelay } from '@core/utils/other'
import Avatar from '@core/components/Avatar'
import FieldDialog from '@core/components/FieldDialog'

export default {
  name: 'list-table',
  components: {
    BRow, BCol, BTable, BPagination,
    Avatar,
    FieldDialog
  },
  props:{
    isDialog: Boolean,
    slim: Boolean,
    showIcon: {
      type: Boolean,
      default: false
    },
    icon: [String, Array],
    selectable: Boolean,  // 是否可选
    showSelect: Boolean, // 是否显示选项栏
    selectMulti: Boolean, // 是否多选

    isSortDesc: {
      type: Boolean,
      default: true
    },
    sortBy: {
      type: String,
      default: '_id'
    },
    filters: {
      type: Object,
      default: ()=>{}
    },
    // Tabs
    tabDefault: {
      type: String,
      default: ''
    },
    tabGroup: {
      type: String,
      default: 'status'
    },
    tabOptions: Array,
    tabTitleColor: Boolean,
    dataName: {
      type: String,
      requried: true
    },
    columns: {
      type: Array,
      required: true
    },
    search: String,
    fetchAsync: Function
  },
  filters: {
    capitalize, mmdd
  },  
  setup(props, {emit}) {
    const { awaitAction } = useDelay()
    const loading = ref(false)
    
    // Table
    const tableRef = ref(null)
    const refetchTable = () => tableRef.value.refresh()
    const perPage = ref(25)
    const total = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5,25,50,100]
    const searchQuery = ref(null)

    const dataMeta = computed(() => {
      const localItemsCount = tableRef.value ? tableRef.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: total.value,
      }
    })
    
    // Filters
    const filterInputs = ref({})
    const filterSelects = ref({})
    // Tabs
    const countGroups = ref([])
    const tabCount = key => {
      if(!key || !countGroups.value || countGroups.value.length===0) return false
      const obj = countGroups.value.find(g=>g._id===key)
      return obj && obj.count || false
    }
    const filterTap = ref(props.tabDefault)

// Click row
    let cancelSelect = ref(null) 
    let rowClickedAction = ()=>{}
    const rowClicked = row => rowClickedAction(row)
    // 外部引用
    let clickRow = ()=> new Promise(res=>{
      rowClickedAction = val=> res(val)
      cancelSelect.value = v=>res(v==='clear'?null:false)
    })

    // Table selection
    if (props.showSelect) props.columns.unshift({ key: 'selected', label:''})
    const selectAllCheckbox =ref(null)
    const selectAll = val=> val ? tableRef.value.selectAllRows() : tableRef.value.clearSelected()
    const selectedData = ref([])
    let onRowSelected = (val)=>{
      selectedData.value = val
    }

    watch([currentPage, perPage, filterSelects, filterTap], refetchTable, {deep: true})
    watch([searchQuery, filterInputs], () => { awaitAction(refetchTable) }, {deep: true})
    watch(selectedData, ()=>{
      if (!selectedData.value.length) selectAllCheckbox.value = false
      emit('update:selected-data', selectedData.value)
    })

    // Action
    const fetchTable = (_, callback) => {
      // Reset
      selectAllCheckbox.value = false
      loading.value = true

      const queryParams = {
        fields: searchQuery.value?props.search:'',
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sort: (props.isSortDesc?'-':'') + props.sortBy
      }

      // Table head filters
      const filtersArry = []
      let filters = {...filterInputs.value, ...filterSelects.value}
      for (let key in filters){
        if (key && filters[key]) filtersArry.push({ [key]: filters[key]})
      }
      // 额外 filters
      if(props.filters) for (let key in props.filters){
        if (key && props.filters[key]) filtersArry.push({ [key]: props.filters[key]})
      }
      // Tab filters
      if (props.tabOptions && filterTap.value) filtersArry.push(JSON.parse(typeof filterTap.value==='string'?filterTap.value:JSON.stringify(filterTap.value)))
      queryParams.filters = JSON.stringify(filtersArry)

      props.fetchAsync(queryParams).then(r => {
        loading.value = false
        total.value = r.total
        countGroups.value = r.countGroups
        callback(r[props.dataName])
      }).catch(() => {
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: i18n('rule.not_available') })
      })
    }

    return {
      i18n, loading, fileUrl, capitalize,

      // Table
      tableRef,
      refetchTable,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,

      filterInputs,
      filterSelects,
      filterTap,

      // Click
      rowClicked,
      clickRow,
      cancelSelect,

      // Select
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,
      
      // Action
      fetchTable,
      countGroups,
      tabCount
    }
  }
}
</script>
<style lang="scss">
.slim {
  th {
    white-space: nowrap;
  }
  td {
    padding: 0.72rem 0.50rem;
  }
  td:first-child {
    padding-left: 1rem
  }
  td:last-child {
    padding-right: 1rem
  }
}
</style>