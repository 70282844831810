<template>
  <q-dialog v-model="isDialogOpen" id="editor-dialog" persistent @hide="resetDialog" class="bg-white">
    <div class="dialog-container bg-white">
      <div class="p-2">
        <q-editor v-model="content" :toolbar="toolbar" min-height="20rem"/>
      </div>
     
      <div class="d-flex justify-content-between gap-2 px-2 py-1 shadow-top bg-light-secondary">
        <q-btn @click="clickSubmit" push no-caps color="primary"><fa-icon icon="check"/></q-btn>
        <q-btn push color="warning" no-caps v-close-popup><fa-icon icon="times"/></q-btn>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  name:'editor-dialog',
  setup(){
    const loading = ref(false)
    const isDialogOpen = ref(false)
    const content = ref('')

    const toolbar = [
      ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
      ['token', 'hr', 'link', 'custom_btn'],
     
      ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
      ['undo', 'redo'],
      ['viewsource'],
       ['print', 'fullscreen'],
    ]

    let submit = ()=>{}
    const clickSubmit = () => submit()

    const open = val => {
      isDialogOpen.value = true
      return new Promise(res => {
        content.value = decodeURIComponent(val||'')
        submit = ()=>{
          res(encodeURIComponent(content.value))
          isDialogOpen.value = false
          loading.value = true
        }
      })
    }
    const resetDialog = ()=>{
      content.value = ''
    }
    return {
      // Dialog
      isDialogOpen,
      open,
      resetDialog,

      clickSubmit,

      toolbar,
      content
    }
  }
}
</script>

<style lang="scss" scope>
@media (min-width: 600px){
  #editor-dialog{
    div.dialog-container {
      min-width: 80%
    }
  }
}
</style>
 