<template>
  <component :is="isDialog?'q-dialog':'div'" id="customer-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" slim :columns="columns" :fetchAsync="fetchCustomers" dataName="customers" :selectable="selectable" selectMulti search="name,phone,email,tags">
        <!-- Add button -->
        <template #add>
          <q-btn v-if="$can('create','Supplier')" push no-caps color="primary" @click.stop="customerEditRef.open().then(v=>v?tableRef.refetchTable():'')">
            <span class="text-nowrap text-capitalize">{{$t('action.add')}}</span>
          </q-btn>
        </template>
        <!-- Name -->
        <template #cell(name)="{item}">
          <component :is="isDialog?'div':'q-btn'" flat padding="0.25rem 0.50rem" @click="customerViewRef.open(item._id).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.files" defaultIcon="user"/>
                <div class="text-left">
                  <div class="h5 m-0">{{item.name}}</div>
                  <small>{{item.customerNo}}</small>
                  <div>
                    <q-chip v-if="item.grade"  size="sm" :color="'light-'+item.grade.color" class="m-0">
                      <q-avatar :color="(item.mlmSeq===1||item.mlmSeq%2===0)?'primary':'danger'" text-color="white">{{item.level}}</q-avatar>
                      <span>{{item.grade.names.find(n=>n.locale === $i18n.locale).name}}</span>
                    </q-chip>
                  </div>
                </div>
              </div>
              <fa-icon v-if="!isDialog" :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </component>
        </template>

        <!-- Balance/Credit/Pension -->
        <template #cell(account)="{item}">
          <div class="d-flex w-100 rounded-lg overflow-hidden">
            <div class="bg-light-primary p-50 flex-even">{{item.balance | price}}</div>
            <div class="bg-light-warning p-50 flex-even">{{item.credit | price}}</div>
            <div class="bg-light-success p-50 flex-even">{{item.pension | price}}</div>
          </div>
        </template>

        <template #cell(delivering)="{item}"><h5 v-if="item.delivery" class="m-0">{{ item.delivery.deliverAt | date}}</h5></template>

        <!-- Actions -->
        <template #cell(actions)="{item}">
          <div class="text-nowrap d-flex gap-3 align-items-center justify-content-center">
            <feather-icon v-if="$can('update','Customer')" @click.stop="customerEditRef.open(item._id).then(v=>v?tableRef.refetchTable():'')" icon="EditIcon" class="text-primary cursor-pointer" size="16"/>
            <feather-icon v-if="$can('delete','Customer')" @click.stop="deleteCustomer(item._id).then(v=>v?tableRef.refetchTable():'')" icon="TrashIcon" class="text-danger" size="16"/>
          </div>
        </template>
      </ListTable>

      <CustomerView ref="customerViewRef"/>
      <CustomerEdit ref="customerEditRef"/>
    </div>
  </component>
</template>


<script>
import { ref, watch } from '@vue/composition-api'
import { price, i18n } from '@core/utils/filter'
import store from '@/store'
import useCustomer from './useCustomer'
import CustomerEdit from './CustomerEdit'
import CustomerView from './CustomerView'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'

export default {
  name: 'customer-list',
  components: {
    CustomerEdit,
    CustomerView,
    Avatar,
    ListTable
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: { price },  
  setup() {
    // Ref
    const customerViewRef = ref(null)
    const customerEditRef = ref(null)
    const { deleteCustomer } = useCustomer()

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'name', label:i18n('form.name'), sortable: true },
      { key: 'account', label:i18n('finance.account'), sortable: true },
      { key: 'phone', label:i18n('form.phone'), sortable: true },
      { key: 'email', label:i18n('form.email'), sortable: true },
      { key: 'tags', label:i18n('form.tags'), sortable: true },
      { key: 'actions', label:i18n('form.actions'), noFilter: true },
    ])

    // Action
    const fetchCustomers = params => store.dispatch('customer/fetchCustomers', params)

    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchCustomers,
      deleteCustomer,

      // Refs
      customerEditRef,
      customerViewRef
    }
  }
}
</script>

<style lang="scss">
#customer-list {
  .dialog-view {
    max-width: 900px;
    width:100%;
  }
}
</style>