<template>
  <q-dialog v-model="isNameDialogOpen" @hide="cancel">
    <div>
      <q-form class="bg-white rounded-lg" @submit="confirm">
        <div class="p-2">
          <h5 class="text-capitalize mb-2 text-primary">{{$t('form.name')}}</h5>
          <div v-for="(item,i) in nameDialogData">
            <q-input :autofocus="i===0" dense hide-bottom-space v-model="item.name" :rules="[val=>Boolean(val)||$t('rule.required')]" :label="$t(`language.${item.locale}._`)"></q-input>
          </div>
        </div>
        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary gap-2">
          <q-btn type="submit" push no-caps color="primary" :label="$t('action.save') | capitalize"/>
          <q-btn v-if="showClearButton" push no-caps color="warning" @click.stop="clear" :label="$t('action.clear') | capitalize"/>
          <q-btn push color="danger" no-caps v-close-popup :label="$t('action.cancel') | capitalize"/>
        </div>
      </q-form>
    </div>
  </q-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize } from '@core/utils/filter'
import $global from '@/globalVariables'

export default {
  name:'name-dialog',
  filters:{
    capitalize
  },
  props:{
    showClearButton:{
      type: Boolean,
      default: false
    }
  },
  setup(){
    const nameDialogData = ref([])
    const isNameDialogOpen = ref(false)
    
    class NameDialog {
      constructor (){}
      confirm(){}
      clear(){}
      cancel(){}
      resetDialog(){
        return nameDialogData.value = $global.locales.map(locale=>({locale, name:null}))
      }

      open(names) {
        isNameDialogOpen.value=true
        nameDialogData.value = []
        $global.locales.forEach(locale=>{
          let added = false
          names.forEach(item=>{
            if(item.locale === locale && !added) {
              nameDialogData.value.push({...item})
              added = true
            }
          })
        })

        return new Promise((res, rej)=>{
          this.confirm = ()=>{
            isNameDialogOpen.value=false
            res(nameDialogData.value) //返回dialog中的names值
            this.resetDialog()
          }
          this.clear = ()=>{
            isNameDialogOpen.value=false
            res(this.resetDialog())
            this.resetDialog()
          }
          this.cancel = () => {
            isNameDialogOpen.value=false
            res(names)
            this.resetDialog()
          }
        })
      }
    }

    const nameDialog = new NameDialog()

    return {
      isNameDialogOpen,
      nameDialogData,
      open: nameDialog.open,
      confirm: nameDialog.confirm,
      clear: nameDialog.clear,
      cancel: nameDialog.cancel,
      resetDialog: nameDialog.resetDialog
    }
  }
}
</script>