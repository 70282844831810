var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isDialog?'q-dialog':'div',{tag:"component",attrs:{"id":"customer-list"},on:{"hide":_vm.cancel},model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_c('div',{class:{'dialog-view':_vm.isDialog}},[_c('ListTable',{ref:"tableRef",attrs:{"slim":"","columns":_vm.columns,"fetchAsync":_vm.fetchCustomers,"dataName":"customers","selectable":_vm.selectable,"selectMulti":"","search":"name,phone,email,tags"},scopedSlots:_vm._u([{key:"add",fn:function(){return [(_vm.$can('create','Supplier'))?_c('q-btn',{attrs:{"push":"","no-caps":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.customerEditRef.open().then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}},[_c('span',{staticClass:"text-nowrap text-capitalize"},[_vm._v(_vm._s(_vm.$t('action.add')))])]):_vm._e()]},proxy:true},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c(_vm.isDialog?'div':'q-btn',{tag:"component",staticClass:"w-100 rounded-lg",attrs:{"flat":"","padding":"0.25rem 0.50rem"},on:{"click":function($event){_vm.customerViewRef.open(item._id).then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}},[_c('div',{staticClass:"flex-grow-1 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"flex gap-2 align-items-center flex-nowrap"},[_c('Avatar',{attrs:{"url":item.files,"defaultIcon":"user"}}),_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"h5 m-0"},[_vm._v(_vm._s(item.name))]),_c('small',[_vm._v(_vm._s(item.customerNo))]),_c('div',[(item.grade)?_c('q-chip',{staticClass:"m-0",attrs:{"size":"sm","color":'light-'+item.grade.color}},[_c('q-avatar',{attrs:{"color":(item.mlmSeq===1||item.mlmSeq%2===0)?'primary':'danger',"text-color":"white"}},[_vm._v(_vm._s(item.level))]),_c('span',[_vm._v(_vm._s(item.grade.names.find(function (n){ return n.locale === _vm.$i18n.locale; }).name))])],1):_vm._e()],1)])],1),(!_vm.isDialog)?_c('fa-icon',{staticClass:"text-primary ml-1",attrs:{"icon":['far','chevron-right']}}):_vm._e()],1)])]}},{key:"cell(account)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex w-100 rounded-lg overflow-hidden"},[_c('div',{staticClass:"bg-light-primary p-50 flex-even"},[_vm._v(_vm._s(_vm._f("price")(item.balance)))]),_c('div',{staticClass:"bg-light-warning p-50 flex-even"},[_vm._v(_vm._s(_vm._f("price")(item.credit)))]),_c('div',{staticClass:"bg-light-success p-50 flex-even"},[_vm._v(_vm._s(_vm._f("price")(item.pension)))])])]}},{key:"cell(delivering)",fn:function(ref){
var item = ref.item;
return [(item.delivery)?_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(_vm._f("date")(item.delivery.deliverAt)))]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-nowrap d-flex gap-3 align-items-center justify-content-center"},[(_vm.$can('update','Customer'))?_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"EditIcon","size":"16"},on:{"click":function($event){$event.stopPropagation();_vm.customerEditRef.open(item._id).then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}}):_vm._e(),(_vm.$can('delete','Customer'))?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon","size":"16"},on:{"click":function($event){$event.stopPropagation();_vm.deleteCustomer(item._id).then(function (v){ return v?_vm.tableRef.refetchTable():''; })}}}):_vm._e()],1)]}}])}),_c('CustomerView',{ref:"customerViewRef"}),_c('CustomerEdit',{ref:"customerEditRef"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }