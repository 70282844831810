<template>
  <component :is="isDialog?'q-dialog':'div'" id="variant-list" v-model="isDialogOpen" @hide="cancel">
    <div :class="{'dialog-view':isDialog}">
      <ListTable ref="tableRef" slim :tabOptions="tabOptions" :columns="columns" :fetchAsync="fetchVariants" dataName="variants" :selectable="selectable" search="sku,names.name,variant,price">
        <!-- Name -->
        <template #cell(name)="{ item }">
          <component :is="isDialog?'div':'q-btn'" flat padding="0.25rem 0.50rem" @click="isDialog?'':variantViewRef.open(item._id).then(v=>v?tableRef.refetchTable():'')" class="w-100 rounded-lg">
            <div class="flex-grow-1 d-flex justify-content-between align-items-center">
              <div class="flex gap-2 align-items-center flex-nowrap">
                <Avatar :url="item.files" />
                <q-chip v-if="item.productData" size="sm" color="light-secondary" text-color="white" class="px-0">
                  <q-chip v-if="item.productData" size="sm" color="primary" text-color="white">{{item.productData.names | name}}</q-chip>
                  <span class="mx-50 h5 mb-0">{{item.names | name}}</span>
                </q-chip>
              </div>
              <fa-icon v-if="!isDialog" :icon="['far','chevron-right']" class="text-primary ml-1"/>
            </div>
          </component>
        </template>

        <template #head(quantity.out)>
          <div class="d-flex gap-2 justify-content-center align-items-center text-capitalize">
            {{$t('supply-chain.status.out._')}}
            <feather-icon icon="TrendingDownIcon" class="text-danger" />
          </div>
        </template>
        
        <!-- Price -->
        <template #cell(price)="{ item }">
          {{item.price | price($t(`currency.${item.currency}.sign`))}}
        </template>

        <!-- Actions -->
        <template #cell(actions)="{ item }">
          <q-btn v-if="$can('update','Product')" flat @click.stop="variantEditRef.open({variantId: item._id}).then(v=>v?tableRef.refetchTable():'')"><feather-icon icon="EditIcon" class="text-primary cursor-pointer" size="16"/></q-btn>
          <q-btn v-if="$can('delete','Product')" flat @click.stop="deleteVariant(item._id).then(v=>v?tableRef.refetchTable():'')"><feather-icon icon="TrashIcon" class="text-danger" size="16"/></q-btn>
        </template>
      </ListTable>

      <VariantEdit ref="variantEditRef"/>
      <VariantView ref="variantViewRef"/>
    </div>
  </component>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { price, name, i18n } from '@core/utils/filter'
import store from '@/store'
import useVariant from './useVariant'
import VariantEdit from './VariantEdit'
import VariantView from './VariantView'
import Avatar from '@core/components/Avatar'
import ListTable from '@core/components/ListTable'

export default {
  name: 'variant-list',
  components: {
    VariantEdit, VariantView,
    ListTable,
    Avatar
  },
  props:{
    isDialog: Boolean,
    showIcon: Boolean,
    selectable: Boolean,
    filters: {
      type: Object,
      default: ()=>{}
    },
    sort: String
  },
  filters: {
    price, name
  },
  setup() {
    // Ref
    const variantViewRef = ref(null)
    const variantEditRef = ref(null)
    const { deleteVariant } = useVariant()

    // Tabs
    const tabOptions = ref([
      {key: "all", value: ''},
      {key: "inventory", value: {'productData.isInventory': true }},
      {key: "service", value: {'productData.isInventory': {$ne: true}}}
    ])

    // Table
    const tableRef = ref(null)
    const columns = ref([
      { key: 'name', label:i18n('form.name'), sortable: true },
      { key: 'sku', label:i18n('form.sku'), sortable: true, icon:'check' },
      { key: 'price', label:i18n('form.price'), sortable: true },
      { key: 'quantity.in', label:i18n('status.in._'), sortable: true },
      { key: 'quantity.onhand', label:i18n('status.onhand._'), sortable: true },
      { key: 'quantity.out', label:i18n('status.out._'), sortable: true },
      { key: 'actions', field:i18n('form.actions'), noFilter: true }
    ])

    // Action
    const fetchVariants = params => store.dispatch('variant/fetchVariants', params)
    
    // Dialog view
    const isDialogOpen = ref(false)
    
    let cancelAction = () => isDialogOpen.value = false
    const cancel = ()=>cancelAction()
    const selectOne = ()=>new Promise(res => {
      // 隐藏column
      columns.value = columns.value.filter(c=>c.key!=='actions')
      isDialogOpen.value = true
      cancelAction = ()=>{
        isDialogOpen.value = false
        res(false)
      }
      watch(tableRef, ref=>ref?.clickRow().then(val=>{
        isDialogOpen.value = false
        res(val)
      }))
    })

    return {
      // Dialog View
      isDialogOpen,
      cancel,
      selectOne,

      // Tab
      tabOptions,
      
      // Table
      columns,
      tableRef,

      // Action
      fetchVariants,
      deleteVariant,

      // Refs
      variantViewRef,
      variantEditRef
    }
  }
}
</script>
<style lang="scss">
#variant-list {
  .dialog-view {
    max-width: 900px;
    width:100%;
  }
}
</style>