<template>
  <component :is="isPage?'div':'q-dialog'" id="variant-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon :icon="variantData._id?'pen':'plus'" :class="variantData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`supply-chain.variant.${variantData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: variantData._id?'variant-edit':'variant-add', params:{variantId: variantData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>

      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" class="h-100" delay='600' :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <div class="p-2 flex gap-2 flex-column flex-sm-row">
              <!-- is active -->
              <q-toggle v-model="variantData.isActive" checked-icon="check" color="primary" class="flex-even flex-size-6"  :label="variantData.isActive?$t('form.active'):$t('action.inactive')|capitalize" unchecked-icon="clear" />
              <!-- Variant name -->
              <q-input v-if="!variantData.names || variantData.names.length !== variantData.names.filter(item=>Boolean(item.name)).length" outlined dense hide-bottom-space v-model="variantData.name" :label="$t('form.name')|capitalize"
                class="flex-grow-1 m-0 flex-size-4" :rules="[val=>false]" @click.stop.prevent="nameDialogRef.open(variantData.names).then(e=>variantData.names=e)">
                <template #prepend><fa-icon :icon="['far','font-case']" fixed-width class="mr-25 text-danger"/></template>
              </q-input>
              <div v-else class="flex-grow-1 flex-size-4">
                <q-btn-group class="flex-wrap">
                  <q-btn v-for="(item, i) in variantData.names" color="primary" :key="item.locale" class="flex-grow-1" :label="item.name" @click.stop="nameDialogRef.open(variantData.names).then(e=>variantData.names=e)">
                    <q-badge rounded color="warning" floating>{{item.locale}}</q-badge>
                  </q-btn>
                </q-btn-group>
              </div>
              <!-- SKU -->
              <q-input type="text" outlined dense hide-bottom-space v-model="variantData.sku" class="flex-even m-0 flex-size-4"
                :label="$t('form.sku')|capitalize" :rules="[val=>Boolean(val), val=> val.length<=30 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['far','barcode-read']" fixed-width class="text-danger mr-25"/></template>
              </q-input>
              <!-- price -->
              <q-input type="number" outlined dense hide-bottom-space v-model="variantData.price" :label="$t('form.price')|capitalize" step="0.01"
                class="flex-even m-0 flex-size-4" :rules="[val=>val>=0 || $t('rule.positive')]" @input="val=>variantData.price = toNumber(val)">
                <template #prepend><small class="text-bold text-primary mr-25">{{variantData.currency}}</small></template>
              </q-input>

              <q-input type="number" outlined dense hide-bottom-space v-model="variantData.marketPrice" :label="$t('form.market_price')|capitalize"
                step="0.01" class="flex-even m-0 flex-size-4" @input="val=>variantData.marketPrice=toNumber(val)">
                <template #prepend><small class="text-bold text-primary mr-25">{{variantData.currency}}</small></template>
              </q-input>

              <template v-if="isInventory">
                <!-- volume and weight -->
                <q-input type="number" outlined dense hide-bottom-space v-model="variantData.weight" :label="$t('form.weight')|capitalize" step="0.01"
                  class="flex-even m-0 flex-size-4" :rules="[val=>!val || val>=0 || $t('rule.positive')]" @input="val=>variantData.weight=toNumberCeil(val)">
                  <template #prepend><fa-icon :icon="['far','weight-hanging']" fixed-width class="text-primary mr-25"/></template>
                  <template #append><small class="text-bold text-primary mr-25">{{$global.default_weight}}</small></template>
                </q-input>

                <q-input type="number" disable filled dense hide-bottom-space v-model="variantData.volume" :label="$t('form.volume')|capitalize" 
                  step="0.01" class="flex-even m-0 flex-size-4">
                  <template #prepend><fa-icon :icon="['far','cube']" fixed-width class="text-primary mr-25"/></template>
                  <template #append><small class="text-bold text-primary mr-25">{{$global.default_volume}}</small></template>
                </q-input>

                <!-- Size -->
                <q-input type="number" outlined dense hide-bottom-space v-model="variantData.size[0]" :label="$t('size.length')|capitalize"
                  class="flex-even m-0 flex-size-4" :rules="[val => !val || val>=0 || $t('rule.positive')]" @input="val=>variantData.size[0]=toNumberCeil(val, 0)" @change="updateVolume">
                  <template #append><small class="text-bold text-primary mr-25 d-none d-sm-inline">{{$global.default_size}}</small></template>
                </q-input> x 
                <q-input type="number" outlined dense hide-bottom-space v-model="variantData.size[1]" :label="$t('size.width')|capitalize"
                  class="flex-even m-0 flex-size-4" :rules="[val => !val || val>=0 || $t('rule.positive')]" @input="val=>variantData.size[1]=toNumberCeil(val, 0)" @change="updateVolume">
                  <template #append><small class="text-bold text-primary mr-25 d-none d-sm-inline">{{$global.default_size}}</small></template>
                </q-input> x 
                <q-input type="number" outlined dense hide-bottom-space v-model="variantData.size[2]" :label="$t('size.height')|capitalize"
                  class="flex-even m-0 flex-size-4" :rules="[val => !val || val>=0 || $t('rule.positive')]" @input="val=>variantData.size[2]=toNumberCeil(val, 0)" @change="updateVolume">
                  <template #append><small class="text-bold text-primary mr-25 d-none d-sm-inline">{{$global.default_size}}</small></template>
                </q-input>
              </template>
              <!-- Description -->
              <q-input type="textarea" outlined dense hide-bottom-space v-model="variantData.description" rows="2"
                :label="$t('form.description')|capitalize" class="flex-even m-0 flex-size-6" :rules="[ val=>!val || val.length<=500 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','align-left']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
            </div>

            <!-- Image -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','image']" fixed-width />
                {{$t('form.image')}}
              </span>
            </h5>
            <FileUpload size="160px" :files.sync="variantData.files" model="variant" :modelId="variantData._id" limit="5" class="p-2"/>
            
            <!-- html -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 justify-content-between align-items-center">
              <span class="d-flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','file-code']" fixed-width />
                {{$t('form.html')}}
              </span>
              <q-btn round flat color="primary" @click.stop="editorDialogRef.open(variantData.html).then(c=>$set(variantData,'html',c))"><fa-icon :icon="['fad','pen']"/></q-btn>
            </h5>
            <div v-html="decodeURIComponent(variantData.html||'')" style="min-height:5rem" class="inner-shadow rounded-lg overflow-auto m-2 p-50"/>
          </div>
        </component>
        
        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary" style="z-index:10">
          <q-btn type="submit" push no-caps color="primary" :label="capitalize($t('action.save'))" :loading="loading"/>
          <q-btn push no-caps color="warning" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))" :loading="loading"/>
        </div>
      </q-form>
      <NameDialog ref="nameDialogRef" />
      <EditorDialog ref="editorDialogRef" />
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </component>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize, toNumberCeil, toNumber, i18n } from '@core/utils/filter'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { Toast, Dialog } from '@core/utils/other'
import FileUpload from '@core/components/file-upload/FileUpload'
import useVariant from '@/views/supply-chain/variant/useVariant'
// name dialog
import NameDialog from '@core/components/NameDialog'
import EditorDialog from '@core/components/EditorDialog'

export default {
  name: 'variant-edit',
  components: {
    FileUpload,
    NameDialog,
    EditorDialog
  },
  filters:{
    capitalize
  },
  props: {
    isInventory: Boolean,
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    },
  },
  setup(props){
    // Ref
    const loading = ref(false)
    const nameDialogRef = ref(null)
    const editorDialogRef = ref(null)

    const { route, router } = useRouter()
    const {
      variantData,
      resetVariant,
      fetchVariant,

      // Action in Product module
      deleteVariant
    } = useVariant()

    // 计算体积
    const updateVolume = () => {
      const size = variantData.value.size
      //cm to CBM
      variantData.value.volume = toNumberCeil(size[0]*size[1]*size[2]/1000000)
    }

    // Actions
    let submit = ()=>{
      loading.value = true
      store.dispatch(variantData.value._id?'variant/updateVariant':'variant/addVariant', variantData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetVariant()
      if (route.value.params.variantId) {
        loading.value = true
        fetchVariant(route.value.params.variantId, ()=>{
          loading.value = false
        })
      }
    }

    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async ({productId, variantId}) => {
      resetVariant()
      isDialogOpen.value = true
      if(variantId) {
        loading.value = true
        await fetchVariant(variantId, ()=> loading.value = false)
      }
      return new Promise(res=>{
        if(!productId && !variantId) return submit = () => res(variantData.value) // new product + new variant
        submit = () => { 
          loading.value = true
          store.dispatch(variantId?'variant/updateVariant':'variant/addVariant', {productId, variant: variantData.value}).then(r => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(r.variant)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ icon: 'error', title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

    return {
      loading,
      capitalize, toNumber, toNumberCeil,
      updateVolume,
      variantData,

      // Dialog
      isDialogOpen,
      open,
      cancel,

      // submit
      clickSubmit,
      
      // ref
      nameDialogRef,
      editorDialogRef,

      // Actions in product module
      deleteVariant
    }
  }
}
</script>
<style lang="scss">
#variant-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
  .q-textarea {
    .q-field__control{
      padding-right: 2px
    }
  }
}
</style>