<template>
  <div class="con-upload" :class="limit=='1'?'w-auto':''">
    <view-upload v-if="viewActive" :src="viewSrc" />
    <div class="con-img-upload d-flex flex-wrap gap-1 ml-n25">
      <div v-for="img in srcs" :class="{ 'fileError':img.error, 'fileSuccess':(img.percent >= 100), 'sm':small }" :key="img.uid" :style="{width:size, height:size}" class="img-upload flex-shrink-0 m-50">
        <q-btn type="button" flat padding="none" :loading="img.loading" class="btn-x-file" @click="removeFile(img)" >
          <i translate="no" class="material-icons notranslate">clear</i>
        </q-btn>
        <button type="button" v-if="showUploadButton" :class="{'on-progress':img.percent, 'ready-progress':img.percent >= 100}" :style="{height: `${img.percent}%`}" class="btn-upload-file" @click.prevent.stop="upload(img.uid)">
          <i translate="no" class="material-icons notranslate">{{ img.percent >= 100?img.error?'report_problem':'cloud_done':'cloud_upload' }}</i>
          <span>{{ img.percent }} %</span>
        </button>
        <q-img v-if="img.src" :key="img.uid" :src="img.src" @touchend="viewImage(img.src,$event)" @click="viewImage(img.src,$event)" />
        <h4 v-if="!img.src" class="text-archive">
          <i translate="no" class="material-icons notranslate">description</i>
          <span>{{ img.name }}</span>
        </h4>
        <!-- <div v-if="img.src && !img.loading" style="height:12.5%;bottom:0;z-index:201" class="position-absolute bg-success text-white w-100 text-center">
          <div class="mt-25">{{ img.name }}</div>
        </div> -->
      </div>

      <div :class="{ 'sm': small, 'on-progress-all-upload':percent != 0, 'is-ready-all-upload':percent >= 100, 
        'disabled-upload':$attrs.hasOwnProperty('disabled') || limit?srcs.length >= Number(limit):false }" class="con-input-upload m-50" :style="{width:size, height:size}">
        <input v-bind="$attrs" :disabled="$attrs.disabled || limit?srcs.length >= Number(limit):false" :type="type" @change="getFiles">
        <span class="text-input">{{ text }}</span>
        <span :style="{width:`${percent}%`}" class="input-progress"></span>
        <button v-if="showUploadButton" :disabled="srcs.length == 0" type="button" title="Upload" class="btn-upload-all vs-upload--button-upload" @click="upload('all')">
          <i translate="no" class="material-icons notranslate">cloud_upload</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import './fileUpload.scss'
  import { ref, watch } from '@vue/composition-api'
  import viewUpload from './viewUpload'
  import { fileUrl } from '@core/utils/filter'
  import { Toast } from '@core/utils/other'
  import { v4 as uuidv4 } from 'uuid'
  import { i18n } from '@core/utils/filter'
  import store from '@/store'
  
  export default {
    name: 'FileUpload',
    components:{
      viewUpload
    },
    inheritAttrs:false,
    props:{
      model:{
        type: String,
        require: true
      },
      modelId: String,
      files:{ //导入已有的files => only for edit
        default:()=>[],
        type: Array
      },
      formDataName:{
        default:'files',
        type:String
      },
      type:{
        type: String,
        default: 'file'
      },
      text:{
        default:'Upload File',
        type:String
      },
      textMax:{
        default:'Maximum of files reached',
        type:String
      },
      limit:{
        default:null,
        type:[Number,String]
      },
      headers:{
        default:null,
        type:Object
      },
      automatic:{
        default: true,
        type: Boolean
      },
      showUploadButton: {
        default: true,
        type: Boolean
      },
      small: {
        type: Boolean,
        defailt: false
      },
      size: {
        type: String
      }
    },
    setup(props, {emit}) {
      const percent = ref(0)
      const viewActive = ref(false)
      const viewSrc = ref(null)
      const srcs = ref([])

      watch(percent, val=>{
        if(val >= 100) {
          srcs.value.forEach(f=>f.percent = 100)
          setTimeout(()=> percent.value = 0, 500)
        }
      })
      watch(()=>props.files, val=>{
        if(!val || val.length===0) return
        val.forEach(f=>{
          // file._id 重复则忽略
          if (!srcs.value.find(s=>s._id===f._id)) srcs.value.push({
            ...f,
            uid: uuidv4(),
            src: fileUrl(f.url),
            success: true,
            percent: 100,
            loading: false
          })
        })
      }, {immediate: true})

      let lastTap = 0
      const viewImage = (src, evt) => {
        let timeout
        let eventx = (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch)) ? 'touchstart' : 'click';
        if (eventx == 'click'){
          viewActive.value = true
          viewSrc.value = src
        } else {
          if(evt.type == 'touchend'){
            let currentTime = new Date().getTime()
            let tapLength = currentTime - lastTap
            clearTimeout(timeout)
            if (tapLength < 500 && tapLength > 0) {
              viewActive.value = true
              viewSrc.value = src
              preventDefault()
            }
            lastTap = currentTime
          }
        }
      }

      const removeFile = file => {
        if (!file._id) srcs.value = srcs.value.filter(f=>f.uid!==file.uid) // 删除上传不成功的文件

        file.loading = true
        store.dispatch(`${props.model}/removeFile`, {
          modelId: props.modelId,
          fileId: file._id
        }).then(() => {
          file.loading = false
          srcs.value = srcs.value.filter(f=>f.uid!==file.uid)
          emit('update:files', props.files.filter(f=>f._id!==file._id)) //移除 props.files
          Toast.fire({icon:'success', title: i18n('message.files_removed')})
        }).catch(e=>{
          file.loading = false
          Toast.fire({icon:'error', title: e.message})
          console.log(e)
        })
      }
      
      const getFiles = e => {
        let count = srcs.value.length
        // 确保所有图片抖载入后, 再开始上传
        Promise.all(Array.from(e.target.files).map(f=>formatFileAsync(f))).then(()=>{
          if (props.automatic) return upload('all')
        })

        function formatFileAsync (file) { 
          return new Promise(resolve => {
            if(props.limit && ++count > Number(props.limit)) return resolve()
            if (/image.*/.test(file.type)) {
              const reader  = new FileReader()
              reader.readAsDataURL(file)
              reader.onload = (e) => {
                const image = new Image()
                image.onload = () => (srcs.value.push({ uid: uuidv4(), src:image.src, name:file.name, type:'image', data: file, percent:null, success: false, error:false, loading: false }), resolve())
                image.src = e.target.result
              }
            } else {
              srcs.value.push({ uid: uuidv4(), src:null, name:file.name, type:(/video.*/.test(file.type))?'video':'file', data: file, percent:null, success: false, error:false, loading: false })
              resolve()
            }
          })
        }
      }

      const upload = async uid => {
        let postFiles = srcs.value.filter(f=>!f.success)
        if (uid !== 'all') postFiles = postFiles.filter(f=>f.uid === uid)
        
        // 单独上传
        await Promise.all(postFiles.map(f=>{
          const formData = new FormData()
          formData.append(props.formDataName, f.data, f.name)
          f.loading = true
          return store.dispatch(`${props.model}/addFile`, {modelId: props.modelId, formData, onUploadProgress: e => {
            if (e.total > 0) {
              let p = e.loaded / e.total * 100
              f.percent = Math.trunc(p)
              if(uid === 'all')  percent.value = Math.trunc(p)
            }
          }}).then(response=>{
            f.loading = false
            f.success = true
            f.error = false
            f.percent = 100
            f._id = response.file._id // 数据库的图片_id
            return response.file
          }).catch(e=> {
            f.percent = null
            f.loading = false
            f.error = true
            Toast.fire({icon:'error', title:e.message})
            console.log(e)
            throw Error(i18n('message.upload_failed'))
          })
        })).then(files=> {
          emit('update:files', props.files.concat(files)) //更新 props.files
          Toast.fire({icon:'success', title: i18n('message.files_uploaded')})
        }).catch(e=>{
          Toast.fire({icon:'error', title:e.message})
          console.log(e)
        })
      }

      return {
        srcs,
        percent,
        viewActive,
        viewSrc,

        viewImage,
        removeFile,
        getFiles,
        upload
      }
    }
  }
</script>

<style lang="scss" scoped>
.con-img-upload {
  .img-upload.sm{
    width: 170px;
    height: 170px;
  }
  div.q-img {
    position: unset;
  }
}

.con-input-upload.sm {
  width: 170px;
  height: 170px;
}
</style>