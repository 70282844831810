<template>
  <component :is="isPage?'div':'q-dialog'" id="sale-edit" v-model="isDialogOpen" @hidden="cancel" :persistent="persistent">
    <div class="card m-0 h-100 shadow-sm overflow-hidden" :class="{'dialog-view':!isPage}">
      <div v-if="!isPage" class="bg-light-secondary p-2 d-flex align-items-center flex-wrap gap-2 shadow-sm" style="z-index:10">
        <fa-icon :icon="saleData._id?'pen':'plus'" :class="saleData._id?'text-primary':'text-success'"/>
        <h4 class="m-0">{{$t(`supply-chain.sale.${saleData._id?'edit':'add'}`)}}</h4>
        <q-space />
        <q-btn flat padding="5px" color="primary" :to="{name: saleData._id?'sale-edit':'sale-add', params:{saleId: saleData._id}}">
          <fa-icon icon="expand"/>
        </q-btn>
      </div>
      <q-form @submit="clickSubmit" class="h-100 d-flex flex-column">
        <component :is="isPage?'div':'q-scroll-area'" ref="scrollAreaRef" class="h-100" delay='600'
          :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.75 }" 
          :bar-style="{ right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px', opacity: 0.2 }">
          <div>
            <!-- Details -->
            <div class="flex p-2 gap-2 flex-column flex-sm-row">
              <!-- Ordered at -->
              <q-input outlined hide-bottom-space v-model="saleData.orderedAt" mask="date" :rules="[val=>Boolean(val)||$t('rule.required'),'date']" class="mb-0 flex-even flex-size-3">
                <template #prepend><fa-icon :icon="['fad','calendar-minus']" fixed-width class="text-danger mr-1"/></template>
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                      <q-date v-model="saleData.orderedAt" style="height:376px">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
              <!-- trackNo -->
              <q-input type="text" outlined hide-bottom-space clearable v-model="saleData.trackNo" class="mb-0 flex-even flex-size-3" 
                :label="$t('form.track_number')|capitalize" :loading="loading" autocomplete="off"
                :rules="[ val=>!val||val.length<=50 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','satellite-dish']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
              <!-- tags -->
              <q-select v-model="saleData.tags" outlined :label="$t('form.tags')|capitalize" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input 
                :placeholder="$t('message.enter_add_new')" class="flex-even m-0 flex-size-3">
                <template #selected-item="scope">
                  <q-chip dense removable class="mr-50" @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
                </template>
                <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary mr-25"/></template>
              </q-select>
              <!-- Description -->
              <q-input type="textarea" outlined hide-bottom-space v-model="saleData.description" rows="2" :loading="loading"
                :label="$t('form.description')|capitalize" class="flex-even m-0 flex-size-6" :rules="[ val=>!val || val.length<=500 || $t('rule.too_long')]">
                <template #prepend><fa-icon :icon="['fad','align-left']" fixed-width class="text-primary mr-25"/></template>
              </q-input>
            </div>

            <!-- Variants -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-75 text-capitalize d-flex justify-content-between align-items-center">
              <div><fa-icon :icon="['fad','list']" fixed-width class="mr-1"/>{{$t('form.variants')|capitalize}}</span></div>
              <q-btn push round dense color="success" :loading="loading" @click.prevent.stop="addVariant"><fa-icon icon="plus"/></q-btn>
            </h5>
            <q-list separator class="rounded-lg px-50">
              <transition-group name="items-transition">
                <q-item key="-1" v-if="!saleData.variants || saleData.variants.length===0">
                  <q-field hide-bottom-space :value="saleData.variants" borderless :rules="[val=>val.length>0]" class="m-0 p-0 flex-grow-1">
                    <q-item-section class="bg-light rounded-lg align-items-center" @click.prevent.stop="addVariant">
                      <fa-icon :icon="['fad','cauldron']" size="5x" class="text-secondary m-2"/>
                    </q-item-section>
                  </q-field>
                </q-item>
                <q-item v-for="variant in saleData.variants" :key="variant._id">
                  <q-item-section avatar><Avatar :url="variant.files" /></q-item-section>
                  <q-item-section class="align-items-start">
                    <q-chip dense class="pl-0">
                      <q-chip dense :color="(variant.isInventory || variant.product && variant.product.isInventory)?'primary':'warning'" text-color="white" class="mr-50">
                        {{variant.productNames || (variant.product && variant.product.names) | name}}
                      </q-chip>
                    {{variant.names | name}}
                    </q-chip>
                    <small>{{variant.sku}}</small>
                  </q-item-section>
                  <q-item-section>
                    {{variant.price | price($t(`currency.${variant.currency}.sign`))}}
                  </q-item-section>
                  <q-item-section>
                    <q-input type="number" rounded :step="1" :loading="loading" input-class="text-center" color="primary" outlined hide-bottom-space v-model.number="variant.quantity" :rules="[val=>val>0]" class="m-0" @change="variant.quantity=toNumber(variant.quantity, 0)">
                      <template #prepend><q-btn color="danger" push dense round icon="remove" @click.prevent.stop="variant.quantity = Math.max(--variant.quantity,1)" /></template>
                      <template #append><q-btn color="primary" push dense round icon="add" @click.prevent.stop="variant.quantity = Math.max(++variant.quantity,1)" /></template>
                    </q-input>
                  </q-item-section>
                  <q-item-section class="align-items-end">
                    <div class="h5 m-0 align-right text-nowrap">{{variant.quantity*variant.price | toNumber | price($t(`currency.${variant.currency}.sign`)) }}</div>
                  </q-item-section>
                  <q-item-section class="align-items-end">
                    <q-btn color="danger" flat :loading="loading" @click.prevent.stop="saleData.variants = saleData.variants.filter(v=>v._id!==variant._id)"><fa-icon :icon="['fas','trash-alt']" /></q-btn>
                  </q-item-section>
                </q-item>
              </transition-group>
            </q-list>

            <!-- Customer -->
            <h5 class="text-primary bg-light-secondary m-0 gap-2 d-flex px-2 py-1 text-capitalize">
              <fa-icon :icon="['fad','user']" fixed-width />{{$t('member.customer._')}}
            </h5>
            <div class="d-flex px-2 py-1">
              <div v-if="saleData.customer && saleData.customer._id" @click.stop="selectCustomer" class="shadow-sm light-primary d-flex gap-2 border-primary rounded-lg px-1 py-50 bg-light-primary align-items-center">
                <Avatar :url="saleData.customer.files" :defaultIcon="['fad', 'user']" />
                <div class="text-left">
                  <div class="h5 m-0">{{saleData.customer.name}}</div>
                  <small>{{saleData.customer.customerNo}}</small>
                  <div>
                    <q-chip v-if="saleData.customer.grade"  size="sm" :color="'light-'+saleData.customer.grade.color" class="m-0">
                      <q-avatar :color="(saleData.customer.mlmSeq===1||saleData.customer.mlmSeq%2===0)?'primary':'danger'" text-color="white">{{saleData.customer.level}}</q-avatar>
                      <span>{{saleData.customer.grade.names | name}}</span>
                    </q-chip>
                  </div>
                </div>
              </div>
              <q-field v-else hide-bottom-space dense :value="saleData.member" borderless class="mb-0" :rules="[val=>Boolean(val) || $t('rule.required')]">
                <q-btn push no-caps @click.stop="selectCustomer" color="deep-orange">{{$t('member.choose_customer')}}</q-btn>
              </q-field>
            </div>
            
            <!-- Address -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-1 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','truck']" fixed-width/>
                {{$t('form.delivery')}}
              </span>
            </h5>
            <div class="d-flex flex-column gap-3 align-items-start p-2">
              <!-- 派送 / 自提 -->
              <q-field hide-bottom-space dense :value="saleData.address" borderless :rules="[val=>Boolean(val) || $t('rule.required')]">
                <q-btn-group>
                  <q-btn no-caps :loading="loading" :outline="!saleData.toDelivery" :filled="saleData.toDelivery" :color="saleData.toDelivery?'primary':''" icon="fas fa-truck"
                    @click.prevent.stop="chooseToDeliver(true)" :label="$t('form.delivery') | capitalize"/>
                  <q-btn no-caps :loading="loading" :outline="saleData.toDelivery" :filled="!saleData.toDelivery" :color="!saleData.toDelivery?'primary':''" icon="fas fa-hand-holding-box" 
                    :disable="!$global.collectionAddresses || $global.collectionAddresses.length===0"
                    @click.prevent.stop="chooseToDeliver(false)" :label="$t('form.collection') | capitalize"/>
                </q-btn-group>
              </q-field>

              <div v-if="saleData.toDelivery"><!--派送-->
                <div v-if="saleData.address" class="rounded-lg shadow d-flex flex-column" style="min-width:180px">
                  <div class="p-1">
                    <h5 class="text-primary">{{saleData.address.name}}</h5>
                    <div>{{saleData.address.unit}}</div>
                    <div v-for="item in saleData.address.address.split(/[,|.]/)">{{item}}</div>
                    <div>{{saleData.address.city}} {{saleData.address.zip}}</div>
                  </div>
                  <q-btn-group spread outline>
                    <q-btn push class="bg-primary text-white" style="border-top-left-radius:0" :loading="loading" @click.prevent.stop="addressRef.open(saleData.address).then(v=>v?saleData.address=v:'')"><fa-icon icon="pen"/></q-btn>
                    <q-btn push class="bg-danger text-white" style="border-top-right-radius:0" :loading="loading" @click.prevent.stop="saleData.address=null"><fa-icon icon="trash" /></q-btn>
                  </q-btn-group>
                </div>
                <div v-else class="d-flex flex-wrap gap-2">
                  <!-- 新增地址 -->
                  <q-btn push class="bg-light-primary" style="min-height:5rem" @click.stop="addressRef.open().then(v=>v?saleData.address=v:'')"><fa-icon icon="plus-circle" size="3x" class="mx-3"/></q-btn>
                  <!-- 选择现有地址 -->
                  <q-btn v-for="(address,i) in ([]&&saleData.customer&&saleData.customer.addresses)" :key="i" @click.prevent.stop="saleData.address=address" class="rounded-lg"
                    :class="{'inner-shadow border-primary':address.address===(saleData.address && saleData.address.address)}">
                    <q-badge v-if="address.address===(saleData.address && saleData.address.address)" color="transparent" text-color="success" size floating><fa-icon :icon="['fad','check-circle']" size="2x"/></q-badge>
                    <div class="text-left h-100">
                      <h5 class="text-primary">{{address.name}}</h5>
                      <div>{{address.unit}}</div>
                      <div v-for="item in address.address.split(/[,|.]/)" class="text-break">{{item}}</div>
                      <div>{{address.city}} {{address.zip}}</div>
                    </div>
                  </q-btn>
                </div>
              </div>
              <div v-else class="d-flex flex-wrap gap-2"><!--自提-->
                <q-btn v-for="(address,i) in $global.collectionAddresses" :key="i" :loading="loading" @click.prevent.stop="saleData.address=address" class="rounded-lg"
                  :class="{'inner-shadow border-primary':address.address===(saleData.address && saleData.address.address)}">
                  <q-badge v-if="address.address===(saleData.address && saleData.address.address)" color="transparent" text-color="success" class="p-0" size floating><fa-icon :icon="['fad','check-circle']" size="2x"/></q-badge>
                  <div>
                    <h5 class="text-primary">{{address.name}}</h5>
                    <div>{{address.unit}}</div>
                    <div v-for="item in address.address.split(/[,|.]/)" class="text-break">{{item}}</div>
                    <div>{{address.city}} {{address.zip}}</div>
                  </div>
                </q-btn>
              </div>
            </div>

            <!-- Billing -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-1 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center">
                <fa-icon :icon="['fad','usd-square']" fixed-width/>
                {{$t('supply-chain.invoice.billing')|capitalize}} ({{$t('table.optional')}})
              </span>
            </h5>
            <div class="d-flex flex-column gap-3 align-items-start p-2">
              <q-btn v-if="!saleData.billing" push class="bg-light-primary" style="min-height:5rem" @click.stop="addressRef.open(null, {corpName: true, unit:false, zip:false, city: false}).then(v=>v?saleData.billing=v:'')">
                <fa-icon icon="plus-circle" size="3x" class="mx-3"/>
              </q-btn>
              <div v-if="saleData.billing" class="rounded-lg shadow d-flex flex-column" style="min-width:180px">
                <div class="p-1">
                  <h5 v-if="saleData.billing.corpName" class="text-primary">{{saleData.billing.corpName}}</h5>
                  <h5 class="text-primary">{{saleData.billing.name}}</h5>
                  <div>{{saleData.billing.phone}}</div>
                  <div v-for="item in saleData.billing.address.split(/[,|.]/)" class="text-break">{{item}}</div>
                </div>
                <q-btn-group spread outline>
                  <q-btn push class="bg-primary text-white" style="border-top-left-radius:0" :loading="loading"
                    @click.prevent.stop="addressRef.open(saleData.billing, {corpName: true, unit:false, zip:false, city: false}).then(v=>v?saleData.billing=v:'')"><fa-icon icon="pen"/></q-btn>
                  <q-btn push class="bg-danger text-white" style="border-top-right-radius:0" :loading="loading" @click.prevent.stop="saleData.billing=null"><fa-icon icon="trash" /></q-btn>
                </q-btn-group>
              </div>
            </div>

            <!-- Image -->
            <h5 class="text-primary bg-light-secondary m-0 px-2 py-1 d-flex gap-2 align-items-center">
              <span class="flex gap-2 align-items-center text-capitalize">
                <fa-icon :icon="['fad','image']" fixed-width />
                {{$t('form.image')}}
              </span>
            </h5>
            <FileUpload size="160px" model="sale" :modelId="saleData._id" :files.sync="saleData.files" limit="5" class="px-2 py-1"/>
          </div>
        </component>
        <div class="d-flex justify-content-between px-2 py-1 shadow-top bg-light-secondary"  style="z-index:10">
          <q-btn push no-caps color="primary" class="mr-2" type="submit" :loading="loading" :label="capitalize($t('action.save'))"/>
          <q-btn push no-caps color="danger" :loading="loading" @click.prevent.stop="cancel" :label="capitalize($t('action.cancel'))"/>
        </div>
      </q-form>
      <VariantList ref="variantListRef" showIcon isDialog/>
      <CustomerList ref="customerListRef" showIcon isDialog />
      <AddressDialog ref="addressRef" />
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </component>
</template>

<script>
import $global from '@/globalVariables'
import { useRouter } from '@core/utils/utils'
import { ref, set }  from '@vue/composition-api'
import { price, capitalize, toNumber, i18n, name } from '@core/utils/filter'
import { Toast, Dialog } from '@core/utils/other'
import store from '@/store'
import useSale from './useSale'
import Avatar from '@core/components/Avatar'
import FileUpload from '@core/components/file-upload/FileUpload'
import { v4 as uuidv4 } from 'uuid'

import AddressDialog from '@core/components/AddressDialog'
import CustomerList from '../../member/customer/CustomerList'
import VariantList from '../variant/VariantList'

export default {
  name: 'sale-edit',
  components: {
    FileUpload, Avatar,
    AddressDialog,
    CustomerList,
    VariantList
  },
  filters: {
    price, capitalize, name, toNumber
  },
  props:{
    isPage: Boolean,
    persistent: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    // Ref
    const addressRef = ref(null)
    const variantListRef = ref(null)
    const customerListRef = ref(null)

    const { route, router } = useRouter()
    const {
      loading,
      saleData,
      resetSale,
      fetchSale,
    } = useSale()

    let submit = ()=>{
      loading.value = true
      store.dispatch(supplierData.value._id?'sale/updateSale':'sale/addSale', saleData.value).then(() => {
        loading.value = false
        Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
        router.go(-1)
      }).catch(e =>{
        loading.value = false
        Dialog(false).fire({ icon: 'error', title: e.message })
      })
    }
    const clickSubmit = () => submit()

    // Page view
    if (props.isPage) {
      resetSale()
      if (route.value.params.saleId) {
        loading.value = true
        fetchSale(route.value.params.saleId, ()=>{
          loading.value = false
          if(saleData.value && saleData.value.variants && saleData.value.variants.length>0) saleData.value.variants.forEach(v => v.uid = uuidv4())
        }, ()=>loading.value=false)
      }
    }

    // Dialog view
    const isDialogOpen = ref(false)
    let cancelAction = () => router.go(-1)
    const cancel = ()=>cancelAction()
    const open = async id => {
      resetSale()
      isDialogOpen.value = true
      if(id) {
        loading.value = true
        await fetchSale(id, ()=>{
          loading.value = false
          if(saleData.value && saleData.value.variants && saleData.value.variants.length>0) saleData.value.variants.forEach(v => v.uid = uuidv4())
        },
        ()=>{
          loading.value = false
          isDialogOpen.value = false
        })
      }
      return new Promise(res=>{
        submit = () => {    
          loading.value = true
          store.dispatch(id?'sale/updateSale':'sale/addSale', saleData.value).then(() => {
            loading.value = false
            Toast.fire({icon: 'success', title: capitalize(i18n('form.success'))})
            isDialogOpen.value = false
            res(true)
          }).catch(e =>{
            loading.value = false
            Dialog(false).fire({ icon: 'error', title: e.message })
          })
        }
        cancelAction = ()=>{
          isDialogOpen.value = false
          res(false)
        }
      })
    }

    // 客户
    const selectCustomer = ()=> customerListRef.value.selectOne().then(v=> {
      if (v!==false) set(saleData.value, 'customer', v)
    })

    // Variants
    const addVariant = function() {
      variantListRef.value.selectOne().then(v=>{
        if(v===false) return
        const duplicated = saleData.value.variants && saleData.value.variants.find(variant=>variant._id === v._id)
        if (duplicated) return Toast.fire({icon:'error', title: i18n('message.duplicated')})
        saleData.value.variants.push({...v, variant:v.variant||v._id,quantity:1})
      })
    }

    // 派送 / 自提
    const chooseToDeliver = val => {
      saleData.value.toDelivery = val
      saleData.value.address = null
      if (!val && $global.collectionAddresses.length===1) saleData.value.address = $global.collectionAddresses[0]
    }
    
    return {
      loading, capitalize, toNumber,
      // Ref
      addressRef,
      variantListRef,
      customerListRef,
      
      saleData,
      clickSubmit,

      // Dialog
      isDialogOpen,
      open,
      cancel,

      // Action
      fetchSale,
      selectCustomer,

      // Varaint
      addVariant,

      // 派送/ 自提
      chooseToDeliver
    }
  },
}
</script>

<style lang="scss">
#sale-edit {
  .dialog-view {
    max-width: 800px;
    width:100%;
  }
  .q-textarea {
    .q-field__control{
      padding-right: 2px
    }
  }
  .text-break {
    white-space: pre-line
  }

  .items-transition-enter-active {
    transition: all .3s ease;
  }
  .items-transition-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .items-transition-enter, .items-transition-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }

  // q-list
  .q-list--separator {
    .q-item {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      &:first-child {
        border-top: 0 transparent;
      }
    }
  }
}
</style>